<template>
    <OpenSource />
    <Sidebar />
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import OpenSource from '@/components/projects/open-source.vue'

export default {
  components: { Sidebar, OpenSource }
}
</script>
