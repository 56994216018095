<template>
    <Podcasting />
    <Sidebar />
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Podcasting from '@/components/articles/me-in-podcasting.vue'

export default {
  components: { Sidebar, Podcasting }
}
</script>
