<template>
  <h1 class="error-header">(: 404</h1>
  <p class="error-body">Page Not Found, Try Another Page.</p>
</template>

<style scoped>
.error-header{
    text-align:center;

}
.error-body{
    text-align:center;
}
</style>