<template>
  <div class="rightcolumn">
    <div class="card">
      <h2>Who is Majid?</h2>
      <!-- <div class="fakeimg" style="height:100px;">Image</div> -->
      <!-- <img src="../assets/images/my-image.jpg" class="realimg" alt="Majid" style="height: 275px;"> -->
      <!-- <img src="../assets/images/profile.jpg" class="realimg" alt="Majid"> -->
      <p class="justify-text">Experienced Software Engineer with a demonstrated history of working in the information technology and services industry.</p>
      <p class="justify-text">Skilled in Java Enterprise Edition, Java Standard Edition, C# Programming for Desktop and Web, Python, C++ and others.</p>
      <p class="justify-text">Strong engineering professional with a Bachelor's degree focused in Computer Science and Master's degree in Computer Science which was focused in Software Engineering.</p>
    </div>
    <div class="card">
      <h3>Follow Me</h3>
      <ul>
          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCuzhrFmVNrxHr4gUzgTyuvA"><i class="icon-youtube">YouTube</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/majidsd"><i class="icon-github">Github</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://gitlab.com/majidsd"><i class="icon-gitlab"></i>Gitlab</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/majid_hd"><i class="icon-twitter"></i>Twitter</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/abdalmajid-abdalla-196418110/"><i class="icon-linkedin">Linkedin</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="mailto:majid.fms010@gmail.com"><i class="icon-envelope">Email</i></a></li>
      </ul>
    </div>
  </div>
</template>
