<template>
  <div class="leftcolumn">
    <div class="card">
      <h2>MajidPodcat Session One: </h2>
      <h2>Let's have idea about what you can see in Programming And AI</h2>
      <div>
        <p>
          <!-- <img src="../assets/images/sessionone.jpg" alt="Majid Podcast Session One" class="podcast-img"> -->
        </p>
      </div>
      <p>
        This session is a generic session about what you should expect when you want to work in Artificial Intelligence (AI) or Programming, and the main focus are the basics of the stuff that you can see when you do programming or AI.
      </p>
      <p>
        This session is available in 8 platforms, you can check them in this list:
        <ul>
          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCuzhrFmVNrxHr4gUzgTyuvA"><i class="icon-youtube">YouTube</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/majidsd"><i class="icon-github">Apple Podcast</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://gitlab.com/majidsd"><i class="icon-gitlab"></i>Google Podcast</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/majid_hd"><i class="icon-twitter"></i>Spotify</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/abdalmajid-abdalla-196418110/"><i class="icon-linkedin">Anchor</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="mailto:majid.fms010@gmail.com"><i class="icon-envelope">Breaker</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="mailto:majid.fms010@gmail.com"><i class="icon-envelope">Radio Public</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="mailto:majid.fms010@gmail.com"><i class="icon-envelope">Pocket Cast</i></a></li>
      </ul>
      </p>
    </div>
    <div class="card">
      <h2>Majid Podcast Session Two: </h2>
      <h2>It's All About Data</h2>
      <div>
        <p>
          <!-- <img src="../assets/images/sessiontwo.jpg" alt="" class="podcast-img"> -->
        </p>
      </div>
      <p>
        In this session we are going to explore data, what are the world of data science is doing, here we look at the data from the origin generation of it until we process it to get information.
      </p>
      <p>
        This session is available on 7 platforms, you can check them in this lis:
        <ul>
          <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCuzhrFmVNrxHr4gUzgTyuvA"><i class="icon-youtube">YouTube</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/majidsd"><i class="icon-github">Apple Podcast</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://gitlab.com/majidsd"><i class="icon-gitlab"></i>Google Podcast</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://gitlab.com/majidsd"><i class="icon-gitlab"></i>SoundCloud</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/majid_hd"><i class="icon-twitter"></i>Spotify</a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/abdalmajid-abdalla-196418110/"><i class="icon-linkedin">Anchor</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="mailto:majid.fms010@gmail.com"><i class="icon-envelope">Breaker</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="mailto:majid.fms010@gmail.com"><i class="icon-envelope">Radio Public</i></a></li>
          <li><a target="_blank" rel="noopener noreferrer" href="mailto:majid.fms010@gmail.com"><i class="icon-envelope">Pocket Cast</i></a></li>
      </ul>
      </p>
    </div>
    <div class="card">
      <h2>What Should You Expect From My YouTube Channel</h2>
      <h2>The Place That I Talk About Technology Even More!</h2>
      <div>
        <p>
          <!-- <img src="../assets/images/youtube.jpg" alt="" class="podcast-img"> -->
        </p>
      </div>
      <p>
        The idea of my channel is to give the gist of some topics in CS and development.
        Besides the main idea of the channel I will add some demonstrations that related to MajidPodcast, so if there a topic that need a practical demo you can see it here.
        I'm also thinking that if I got time to vlog some of my activities then ai share it in this channel.
      </p>
      <p>You can check the channel here <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCuzhrFmVNrxHr4gUzgTyuvA"><i class="icon-youtube">YouTube</i></a> </p>
    </div>
  </div>
  <Sidebar />
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'

export default {
  components: { Sidebar }
}
</script>
