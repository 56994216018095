<template>
  <div class="header">
    <h1>Welcome to Majid's</h1>
    <p>I hope you can find here something interesting about technology</p>
  </div>
  <div class="topnav">
    <router-link to="/" class="top-bar-link">
      <span>Home</span>
    </router-link>
    <router-link to="/Articles" class="top-bar-link">
      <span>Articles</span>
    </router-link>
    <router-link to="/Projects" class="top-bar-link">
      <span>Projects</span>
    </router-link>
  </div>
  <div class="row">
    <router-view />
  </div>
  <div class="footer">
    <p>Copyright &copy; {{currentDateTime}} All rights reserved</p>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data(){
    return{
      currentDateTime: new Date().getFullYear()
    }
  }
}
</script>
